.container {
    padding: 20px 40px 32px;
    display: flex;
    flex-direction: column;
    color: white;
}


.containerbnb {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
}


.createplayertxt2 {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}




.labelBNB {
  color: #c0cbf7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.create_acc_bnb {
  color: #f1edff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.createplayertxt {
    color: #18ffff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
  }

.containergecko {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    justify-content: space-between;
  }
  

.succesfulMessageTitle {
    font-size: 24px;
    margin-bottom: 40px;
}

.succesfulBack {
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}


.errorText2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.018em;
    color: #fe7a00;
    cursor: pointer;
    text-decoration: underline;
  }