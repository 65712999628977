.faq-wrapper {
  background: #312F69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px;
  /* width: 188px; */
  padding: 6px 8px;
  transition: all .25s;
  cursor: pointer;
}

.faq-wrapper:hover{
  background: #1E1C40;

}

.faqtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #B5BBDF;  
}