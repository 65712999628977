.token-staking {
  background: #14142a;
  border: 2px solid #857dfa;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  position: relative;
  padding: 10px;
}

.fromtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  letter-spacing: -0.018em;
  color: #f7f7fc;
}

.jconfirm-content-pane {
  padding: 5px 0 !important;
}

.jconfirm-content {
  padding: 0 0 8px 0 !important;
}

.swiftwrapper {
  border-radius: 12px;
  border: 1px solid #7770df;
  background: url('https://cdn.worldofdypians.com/tools/bridgebg.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: 0px 32px 64px 0px rgba(17, 17, 17, 0.08);
}

@media only screen and (max-width: 991px) {
  .swiftwrapper {
    border: none;
  }
}

@media only screen and (max-width: 700px) {
  .swiftwrapper {
    background: url('https://cdn.worldofdypians.com/tools/bridgebgMobile.png');
    aspect-ratio: 0.63/0.7;
    background-size: cover;
  }
}

.bridgenow-btn {
  border-radius: 8px;
  background: linear-gradient(91deg, #4dd4d1 0%, #3ca4a1 100%);
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  border: none;
  outline: none;
}

.bottominfotxt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #c0cbf7;
}

.poolbalance-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #c0cbf7;
}

.guidewrapper {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px;
  position: relative;
  padding: 15px;
}

.guidetitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #f7f7fc;
}

.css-ha3bif-MuiTimelineItem-root:before {
  display: none !important;
}

.content-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #c0cbf7;
}

.content-title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #f7f7fc;
}

.greendot {
  background: url("https://cdn.worldofdypians.com/tools/greendot.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
  background-color: #48b4bd !important;
}

.bigseparator {
  background: #565891;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px;
  height: 4px;
}

.passivedot {
  background: #26264f;
  border: 2px solid #565891;
  width: 30px;
  height: 30px;
  background-color: #565891 !important;
}

.greenline {
  background-color: #48b4bd !important;
}

.passiveline {
  background-color: #565891 !important;
}

.migration-banner {
  position: relative;
  background: #282a5c;
  border-radius: 20px;
}

.migration-banner::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-image: url("https://cdn.worldofdypians.com/tools/migrationBannerMainBg.png");
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 20px;
}

@media screen and (max-width: 786px) {
  .migration-banner::before {
    background-image: none;
  }
}

.migration-banner-title {
  color: #f7f7fc;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}

.migration-banner-desc {
  color: #f7f7fc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.216px;
}

.migration-details-wrapper {
  border-radius: 12px;
  border: 1px solid #3b3c68;
  background: #26264f;
  box-shadow: 0px 32px 64px 0px rgba(17, 17, 17, 0.08);
  width: 30%;
}

.migration-details-title {
  color: #f7f7fc;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.324px;
}

.smart-contract-announce {
  color: #d6d8e7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.old-dyp-address {
  color: #f07c58 !important;
  text-align: right;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
  text-decoration-line: underline;
}

.new-dyp-address {
  color: #25daff !important;
  text-align: right;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
  text-decoration-line: underline;
}

.contract-audit-text {
  color: #d6d8e7;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.migration-swap-item {
  color: #d6d8e7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.migration-status-text {
  position: relative;
  color: #fff !important;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  bottom: 48px;
  left: -20px;
  width: 32px;
  text-align: center;
  display: flex;
}
.migration-status-text-2 {
  color: #C0C9FF !important;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.migration-text-wrapper {
  width: 0;
  height: 0;
}

.explore-migration {
  color: #4ed5d2 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.general-bridge-wrapper {
  border-radius: 12px;
  background: url('https://cdn.worldofdypians.com/tools/dypbridgeBg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  min-height: 167px;
}

.base-bridge-wrapper {
  border-radius: 12px;
  background: radial-gradient(50% 50% at 50% 50%, #008dfc 0%, #1b63ff 100%);
  min-height: 58%;
}

.base-bridge-text {
  color: #f0edff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

@media screen and (max-width: 991px) {
  .migration-details-wrapper {
    width: 100%;
  }

  .general-bridge-wrapper {
    border-radius: 12px;
    background: url('https://cdn.worldofdypians.com/tools/dypbridgeBgMobile.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    min-height: 300px;
  }

 
}

.migration-title {
  color: #f7f7fc !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 62.5% */
  letter-spacing: -0.288px;
}

.migration-span {
  color: #7a81b4 !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 150% */
  letter-spacing: -0.144px;
}

.migration-divider {
  width: 100% !important;
  height: 2px;
  background: #3b3c68;
  margin-top: 0;
}

.conversion-rate-wrapper {
  border-radius: 8px;
  border: 1px solid #c0cbf7;
  background: rgba(192, 203, 247, 0.1);
}

.conversion-rate-title {
  color: #7a81b4 !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  letter-spacing: -0.18px;
}

.conversion-rate {
  color: #c0cbf7 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-wrap: nowrap;
  letter-spacing: -0.216px;
}

.conversion-input-container {
  border-radius: 8px;
  border: 1px solid #8e97cd;
  height: 48px;
  width: 100% !important;
}

.conversion-input {
  border-radius: 8px !important;
  height: 100% !important;
  background: #26264f !important;
}

@media screen and (max-width: 786px) {
  .conversion-input {
    width: 50%;
  }
}
