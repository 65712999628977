.primaryInput {
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    background-color: transparent;
    font-size: 20px;
    color: 'white';
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    max-height: 58px;
    /* Glass Effect */
    background: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.coingeckoInput {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    height: 35px;
    border: 1px solid #AAA5EB;
    font-size: 14px;
    color: 'white';
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #272450;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

}

input::placeholder {
    font: 14px;
}

.primaryInput:focus {
    outline-width: 0;
}

input,
select,
textarea,
::placeholder {
    color: #C0CBF7;
    font-size: 14px;
    font-family: Poppins;
    line-height: 30px;
}

/* textarea:focus,
input:focus {
    color: white;
    font-size: 20px;
    font-family: Poppins;
    line-height: 30px;

} */

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid white;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}