.container {
    padding: 20px 40px 32px;
    display: flex;
    flex-direction: column;
    color: white;
}

.succesfulText {
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}