.land-nft-image{
    width: 350px !important;
  
  }
  
  
  @media screen and (max-width: 786px) {
      .popup-span{
          width: 100%;
          font-size: 13px;
      }
  
      .land-nft-image{
        width: 75% !important;
    }
  }
  
  .popup-title-wrapper{
    border-radius: 56px;
  /* background: rgba(255, 255, 255, 0.06); */
  }

  .popup-active{
    box-shadow:0 0 0 10000px rgba(0,0,0,.50);
    top: 0% !important;

}

  .metaverse {
    background: #4ed5d2;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  

  .unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(145deg, #1c1c40, #12122e); */
    background: linear-gradient(145deg, #42b2ff, #1e1e50);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: 20px;
    width: 80px;
    height: 80px;
    box-shadow: 0 0 15px rgba(66, 178, 255, 0.5); /* Neon glow */
    text-align: center;
  }
  
  .time-big-number {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    /* text-shadow: 0 0 5px #42b2ff, 0 0 10px #42b2ff; */
  }
  
  .time-small-number {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    margin-top: 5px;
  }
  
  /* .unit:hover {
    background: linear-gradient(145deg, #42b2ff, #1e1e50);
    transform: scale(1.05);
    transition: 0.3s;
  } */
  
  .timer-separator {
    font-size: 24px;
    color: #fff;
  }
  







  .unit2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: linear-gradient(145deg, #1c1c40, #12122e); */
    background: linear-gradient(145deg, #42b2ff, #1e1e50);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 15px rgba(66, 178, 255, 0.5); /* Neon glow */
    text-align: center;
  }
  
  .time-big-number2 {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    /* text-shadow: 0 0 5px #42b2ff, 0 0 10px #42b2ff; */
  }
  
  .time-small-number2 {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7); 
  }
  
  /* .unit:hover {
    background: linear-gradient(145deg, #42b2ff, #1e1e50);
    transform: scale(1.05);
    transition: 0.3s;
  } */
  
  .timer-separator2 {
    font-size: 12px;
    color: #fff;
  }
  