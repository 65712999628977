.launchpad-wrapper {
  background: #312F69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius:  16px 16px 12px 12px;
  /* padding: 10px; */
  /* width: fit-content; */
  transition: all .25s;
  /* border: 1px solid #312F69; */
  cursor: pointer;
}

.wrapper2 {
  background: #1E1C40 !important;
}

.launchpad-wrapper:focus,
.launchpad-wrapper:hover,
.launchpad-wrapper:active{
  /* border: 1px solid rgb(142, 151, 205);
   */
   background: #1E1C40;
}

.launchpadbg {
  object-fit: fill;
  /* height: 120px; */
  padding: 0;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  min-width: 257px;
  
}


@media screen and (max-width: 786px) {
  .launchpad-wrapper{
    border-radius:  24px 24px 12px 12px;
  }
  .launchpad-bg{
    min-width: none;
  }
}

/* .whitelist-days-left{
  border-radius: 5px;
  opacity: 0.8;
  background: #14142A;
  padding: 4px 8px;
  position: absolute;
  right: 8px;
   top: 8px;
} */

.whitelist-days-left-span{
  color: #4ED5D2 !important;
text-align: center;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.7px;
}