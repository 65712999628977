.buydyp-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #f7f7fc !important;
}

.buydyp-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #d6d8e7 !important;
}

.prev-arrow{
  transform: rotate(180deg);
}

 .novidimg {
  width: fit-content !important;
 }
.vendor-container{
    grid-template-columns: repeat(4,1fr);
    gap: 24px;
}

.vendor-card {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  border: 2px solid #312f69;
  transition: all .25s;
  cursor: pointer;
}

.vendor-image-wrapper {
  background: #2b2a59;
  border: 1px solid #565891;
  border-radius: 8px;
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-image-wrapper {
  background: #2b2a59;
  border: 1px solid #565891;
  border-radius: 8px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-amount {
  padding: 4px 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-radius: 50px;
  color: #4ed5d2 !important;
  background: #396189;
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendor-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f7f7fc !important;
}

.tutorial-text{
  font-weight: 500;
font-size: 14px;
line-height: 21px;
letter-spacing: -0.018em;
color: #4ED5D2 !important;

}


.selected-vendor-card{
  border: 2px solid #857DFA !important;
  background-image: url('https://cdn.worldofdypians.com/tools/vendorCardBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.playlist-title{
  font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #F7F7FC !important;
}

.video-card{
  background: #2B2A59;
border-radius: 8px;
border: 2px solid #2B2A59;
width: fit-content;
transition: all .25s;
}

.selected-video-card{
border: 2px solid #857DFA !important;

}

.video-card-image-wrapper{
 border-radius: 8px;
}

.video-card-title{
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #F7F7FC !important;
}

.video-card-time{
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #C0C9FF !important;
}
@media screen and (max-width: 786px) {
  .vendor-container{
    grid-template-columns: repeat(1, 1fr);
  }
}