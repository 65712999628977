.bridgecard-wrapper {
    padding: 18px;
    background: url('https://cdn.worldofdypians.com/tools/mobileApp.webp');
    border-radius: 12px;
  border: 2px solid #312F69;
    /* background-image: url("./assets/bridgebg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 50%;
    transition: all .25s;
    cursor: pointer;
  }

  .bridgecard-wrapper:focus,
.bridgecard-wrapper:hover,
.bridgecard-wrapper:active{
  border: 2px solid rgb(142, 151, 205);
}

.coming-soon-wrapper {
  border-radius: 5px;
  background: rgba(20, 20, 42,0.8); 
}

.coming-soon-text {
  color: #4ED5D2 !important;
text-align: center;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

  
  .orangediv {
    width: 4px;
    height: 45px;
    position: absolute;
    border-radius: 0px 100px 100px 0px;
    left: -1px;
    background: #8890C4;
  }
  
  .bridgecard-title {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 38px;
    color: #f7f7fc;
  }
  .bridgecard-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #D6D8E7;
    width: 70%;
  }
  
  .bridgecard-btntext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7770e0;
  }


  @media screen and (max-width: 786px) {
    .bridgecard-wrapper{
      width: 100%;
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      transition: all .25s;
      cursor: pointer;
      background-color: rgba(49, 47, 105, 1);

    }
  }
  